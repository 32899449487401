// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import { gpdApi } from './gpd';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  navbar,
  layout,
  [gpdApi.reducerPath]: gpdApi.reducer,
});

export default rootReducer;
