
import { handleApiError } from '@src/utility/errorHandler';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './base';


export const gpdApi = createApi({
    reducerPath: 'gpdApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Gpd'],
    endpoints: (builder) => ({
        getGpd: builder.query({
            query: (filters) => ({
                url: '/api/gpd/search',
                method: 'POST',
                data: filters,
            }),
            transformResponse: (response) => {
                const { data, ...meta } = response;
                return { items: data, meta };
            },
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    handleApiError(error.error, 'Failed to fetch GPD');
                }
            },
            providesTags: ['Gpd']
        }),

    })
});




export const {
    useGetGpdQuery
} = gpdApi;

